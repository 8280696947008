import styled, { css } from 'styled-components';
import { CALYPSO, EERIE, GYPSUM, CANDY_APPLE } from 'HubStyleTokens/colors';
import UILoadingSpinner from 'UIComponents/loading/UILoadingSpinner';
import { BASE_FONT_SIZE } from 'HubStyleTokens/sizes';
const activeHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], CALYPSO);
const thickerErrorHighlight = css(["box-shadow:inset 0 0 0 2px ", ";"], CANDY_APPLE);
export const EditCellLoadingIndicator = styled(UILoadingSpinner).withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellLoadingIndicator",
  componentId: "aj1rbo-0"
})(["position:absolute;left:2px;margin:0;height:100%;top:0;"]);
export const EditCellFloatyWrapper = styled.div.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellFloatyWrapper",
  componentId: "aj1rbo-1"
})(["position:relative;border:1px solid ", ";box-shadow:0 0 0.4em ", ";min-height:100%;max-width:100%;display:flex;flex-direction:column;align-items:flex-start;background:", ";"], EERIE, EERIE, GYPSUM);
const editCellPadding = css(["padding:0.1em 24px;"]);
const heightOfTd = css(["min-height:2.5rem;"]);
export const EditCellPropertyInputWrapper = styled.div.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellPropertyInputWrapper",
  componentId: "aj1rbo-2"
})(["", " ", " flex-grow:1;width:100%;background:", ";", " display:flex;align-items:center;overflow:hidden;z-index:1;"], editCellPadding, heightOfTd, GYPSUM, props => props['aria-invalid'] ? thickerErrorHighlight : ``);
export const EditCellContainer = styled.div.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellContainer",
  componentId: "aj1rbo-3"
})(["flex-grow:1;"]);
export const EditCellErrorText = styled.small.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellErrorText",
  componentId: "aj1rbo-4"
})(["", " color:", ";"], editCellPadding, CANDY_APPLE);
export const EditCell = styled.td.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCell",
  componentId: "aj1rbo-5"
})(["display:block;padding:0 !important;", " textarea,input{height:auto;min-height:auto;font-size:", ";padding:0;border:0;}"], activeHighlight, BASE_FONT_SIZE);