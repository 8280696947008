import styled from 'styled-components';
export const TabComponent = styled.div.withConfig({
  displayName: "TabComponent",
  componentId: "sc-1chv9cg-0"
})(["border-top:var(--view-tabs-border-width) solid var(--view-tabs-border-color);border-left:calc(var(--view-tabs-border-width) / 2) solid var(--view-tabs-border-color);border-right:calc(var(--view-tabs-border-width) / 2) solid var(--view-tabs-border-color);cursor:pointer;", " &:first-of-type{border-left:var(--view-tabs-border-width) solid var(--view-tabs-border-color);border-top-left-radius:var(--view-tabs-border-radius);}&:last-of-type{border-right:var(--view-tabs-border-width) solid var(--view-tabs-border-color);border-top-right-radius:var(--view-tabs-border-radius);}", " background-color:", ";"], props => props.active ? `border-bottom: var(--view-tabs-border-width) solid var(--view-tabs-border-color-unset);` : `border-bottom: var(--view-tabs-border-width) solid var(--view-tabs-border-color);`, props => {
  if (!props.canDropInCurrentLocation) {
    return '';
  }
  //when dragging, we want to ensure that all borders are highlighted with the bright blue to indiciate
  //that the user can drop in the current location. The &&& below increases the specificity to override the &-first-of-type and &-last-of-type borders above
  return `
  &&& {
    border-left: var(--view-tabs-border-width) solid var(--view-tabs-border-color-alternate);
    border-right: var(--view-tabs-border-width) solid var(--view-tabs-border-color-alternate);
    border-bottom: var(--view-tabs-border-width) solid var(--view-tabs-border-color-alternate);
    border-top: var(--view-tabs-border-width) solid var(--view-tabs-border-color-alternate);
  }
  `;
}, props => props.active ? `var(--view-tabs-active-bg-color)` : `var(--view-tabs-inactive-bg-color)`);