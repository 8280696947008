import { useCallback } from 'react';
import { CREATE_INPUT_MODE } from '../../../v2/types/PropertyInputV2Component';
import { StageValidationCache } from '../utils/pipelineStageValidationCache';
import unescapedText from 'I18n/utils/unescapedText';
import { getStageValidations, OVERRIDABLE, VALID } from '../utils/pipelineValidationUtils';
import { RESTART_APPROVAL_WARNING } from '../utils/pipelineValidationTypes';
import { SharedPipelinesCache } from '../../../panels/PipelinesCache';
const stageReferenceWithValidation = (reference, validations) => {
  const validation = validations.find(v => v.targetStageId === reference.id);
  if (!validation || validation.validation === VALID) {
    return reference;
  }
  if (validation.validation === OVERRIDABLE) {
    if (validation.errorCode === RESTART_APPROVAL_WARNING) {
      return Object.assign({}, reference, {
        additionalProperties: Object.assign({}, reference.additionalProperties, {
          errorCode: RESTART_APPROVAL_WARNING
        })
      });
    } else {
      return Object.assign({}, reference, {
        description: unescapedText('governance.pipelineStages.bypassed')
      });
    }
  }
  return Object.assign({}, reference, {
    disabled: true,
    description: unescapedText('governance.pipelineStages.disabled')
  });
};
export const useGetLoadStageReferences = ({
  httpClient,
  objectTypeId,
  pipelineId,
  inputMode,
  objectId
}) => {
  const loadStageReferences = useCallback(async (resolver, searchQuery) => {
    // We don't want to refetch the validation in certain situations. We can opt-in to reading from the cache first.
    // 1. If the user has typed something in the search select box
    // 2. If the input is in creation mode. The set of enabled/disabled stages will never change even on save so we don't need to refetch.
    const shouldReadFromCache = !!searchQuery.query || inputMode === CREATE_INPUT_MODE;
    const searchResponsePromise = resolver.api.search(searchQuery);
    const defaultGetParams = {
      objectTypeId,
      inputMode,
      objectId
    };

    // In situations where we don't have a pipelineId (such the custom object case),
    // we can sequentially fetch the pipelines search response and then validate that batch of stages.
    // This prevents us having to perform validation for all pipelines and all stages upfront.
    if (!pipelineId) {
      const searchResponse = await searchResponsePromise;
      const optionStageIds = searchResponse.references.map(reference => reference.id);
      const stageValidationPromise = StageValidationCache.get(Object.assign({}, defaultGetParams, {
        stageIds: optionStageIds
      }), httpClient, !shouldReadFromCache);
      const stageValidationResponse = await stageValidationPromise;
      const validations = getStageValidations(stageValidationResponse) || [];
      return Object.assign({}, searchResponse, {
        references: searchResponse.references.map(reference => stageReferenceWithValidation(reference, validations))
      });
    }
    const stageValidationPromise = StageValidationCache.get(Object.assign({}, defaultGetParams, {
      pipelineId
    }), httpClient, !shouldReadFromCache);
    const pipelinePromise = SharedPipelinesCache.get({
      objectTypeId,
      httpClient
    });
    return Promise.all([searchResponsePromise, stageValidationPromise, pipelinePromise]).then(([searchResponse, stageValidation, pipelineResponse]) => {
      const pipelineData = pipelineResponse[pipelineId];
      const pipelineStages = pipelineData !== null && pipelineData !== void 0 && pipelineData.stages ? pipelineData.stages.map(stage => {
        var _stage$isApprovalStag;
        return {
          stageId: stage.stageId,
          label: stage.label,
          isApprovalStage: (_stage$isApprovalStag = stage.isApprovalStage) !== null && _stage$isApprovalStag !== void 0 ? _stage$isApprovalStag : null
        };
      }) : [];
      const validations = getStageValidations(stageValidation, pipelineStages) || [];
      return Object.assign({}, searchResponse, {
        references: searchResponse.references.map(reference => stageReferenceWithValidation(reference, validations))
      });
    });
  }, [pipelineId, httpClient, objectId, objectTypeId, inputMode]);
  return loadStageReferences;
};